const API_URL = process.env.REACT_APP_API_URL;

export class MediaApiClient {
  upload = async ({
    file,
    formId,
  }: MediaUploadRequest): Promise<UploadResponse> => {
    const formData = new FormData();

    formData.append('FormId', formId);
    formData.append('File', file);

    const res = await fetch(`${API_URL}/api/public/media`, {
      method: 'POST',
      body: formData,
    });

    if (!res.ok) return null;

    return res.json() as any as UploadResponse;
  };
}

export interface Media {
  fileId: string;
  clientId: string;
  fileKey: string;
  fileName: string;
  size: number;
  contentType: string;
  uploadedAt: string;
  meatdata: { [key: string]: string };
  tags: string[];
}

export interface MediaUploadRequest {
  formId: string;
  file: File;
}

export type UploadResponse = {
  formId: string;
  fileId: string;
  media: Media;
};
