import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';

import { useStore } from '../../util/useStore';

import { Header } from 'components/Header';

// import { Form, IFacebookAppForm } from 'api-clients/FormsApiClient';
import {
  ClientReviews,
  FormsApiClient,
  IFacebookAppForm,
  ListingFeedProvider,
  File,
  FormStatus,
  Form,
} from '../../api-clients/FormsApiClient';
import FileUploadInput from '../../components/FileUploadInput';
import { UploadResponse } from 'api-clients/MediaApiClient';
import { ListingUploadSelect } from 'components/ListingUploadSelect';
import { Spinner } from 'components';

interface FormsPageParams {
  formId: string;
}

interface FileInputFieldProps {
  file: File;
  formId: string;
  name: string;
  onFileUploadComplete: (res: UploadResponse, forField?: string) => void;
  onRemoveFile: (forField?: string) => void;
}

interface FacebookAppFormProps {
  // formId: string;
  // form: Form<IFacebookAppForm>;
}
const ignoreErrorNames = ['logo', 'heroImage', 'staffImage'];
// resolve object from string
// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
const getByString = (path, obj = this, separator = '.') => {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const getStatusLabelClass = (status: FormStatus) => {
  const badgeClass = 'badge ';
  let statusClass = '';
  switch (status) {
    case FormStatus.Sent: {
      statusClass = 'badge-secondary';
      break;
    }

    case FormStatus.InProgress: {
      statusClass = 'badge-primary';
      break;
    }

    case FormStatus.Complete: {
      statusClass = 'badge-sucess';
      break;
    }

    default: {
      break;
    }
  }
  return badgeClass + statusClass;
};

const getStatusText = (status: FormStatus) => {
  let statusText = '';
  switch (status) {
    case FormStatus.Sent: {
      statusText = 'Sent';
      break;
    }

    case FormStatus.InProgress: {
      statusText = 'In Progress';
      break;
    }

    case FormStatus.Complete: {
      statusText = 'Complete';
      break;
    }

    default: {
      break;
    }
  }
  return statusText;
};

const api = new FormsApiClient();

export const FacebookAppForm = (props: FacebookAppFormProps) => {
  const [formData, setFormData] = useState<IFacebookAppForm | null>(null);
  const [formStatus, setFormStatus] = useState(FormStatus.Sent);
  const { formId } = useParams<FormsPageParams>();
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  const [isSavingDraft, setSavingDraft] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const onNotFound = useCallback(() => {
    setNotFound(true);
    setLoading(false);
  }, [setNotFound, setLoading]);

  const {
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    setError,
    setFocus,
    formState: { errors },
    reset,
  } = useForm<IFacebookAppForm>();

  const ErrorSummary = ({ name }: { name: FieldPath<IFacebookAppForm> }) => {
    const fieldError = getByString(name, errors as any);
    if (!fieldError) return null;

    return <span className="text-danger">{fieldError.message}</span>;
  };

  const onLoadForm = useCallback(
    (formData: IFacebookAppForm) => {
      reset({
        ...formData,
        facebook: formData?.facebook || {
          id: '',
          name: '',
          url: '',
        },

        clientReviews: formData?.clientReviews || ClientReviews.RMA,
        otherFiles: formData?.otherFiles || [],
      });
      setLoading(false);
    },
    [setLoading, reset]
  );

  useEffect(() => {
    setLoading(true);
    const getForm = async () => {
      const f = await api.get(formId);

      if (!f) {
        onNotFound();
      }
      setFormStatus(f.status);
      onLoadForm({
        ...(f.formData || ({} as any)),
        clientId: f.clientId,
        formId: f.formId,
        lastUpdated: f.lastUpdated as any,
      });
    };
    getForm();
  }, [formId, setLoading, onLoadForm, onNotFound]);

  const onListingUploaderChange = (value?: string) => {
    console.log(value);
    setValue('listingUploader', value as any);
  };

  const facebookUrl = watch('facebook.url');
  const {
    logo,
    heroImage,
    staffContactDetails,
    staffPic1,
    staffPic2,
    staffPic3,
    staffPic4,
    staffPic5,
    clientReviews,
    clientId,
    useMainContactForAccounts,
    clientProvidedReviews,
    listingUploader,
    otherFiles,
  } = watch();

  const { statusText, statusBadgeClass } = useMemo(() => {
    return {
      statusBadgeClass: getStatusLabelClass(formStatus),
      statusText: getStatusText(formStatus),
    };
  }, [formStatus]);

  const FileInputField = ({
    file,
    formId,
    name,
    onFileUploadComplete,
    onRemoveFile,
  }: FileInputFieldProps) => {
    return (
      <>
        {!file ? (
          <FileUploadInput
            formId={formId}
            forField={name}
            onFileUploadComplete={onFileUploadComplete}
          />
        ) : (
          <>
            <input
              type="hidden"
              {...register(`${name}.fileId` as any)}
              defaultValue={file.fileId}
            />
            <input
              type="hidden"
              {...register(`${name}.fileName` as any)}
              defaultValue={file.fileName}
            />
            <input
              type="hidden"
              {...register(`${name}.tag` as any)}
              defaultValue={file.tag}
            />
            <span>
              {file.fileName}
              <a className="text-danger" onClick={() => onRemoveFile(name)}>
                {' '}
                <i className="fa fa-trash"></i>
              </a>
            </span>
          </>
        )}
      </>
    );
  };

  const fieldFileUploaded = (res: UploadResponse, forField: string) => {
    const file = {
      fileId: res.fileId,
      fileName: res.media.fileName,
      tag: 'file',
    };

    console.log(`File Upload: ${forField}. ${JSON.stringify(file)}`);

    setValue(forField as any, file);
  };

  const otherFilesUploaded = (res: UploadResponse) => {
    const files = otherFiles || [];
    const file = {
      fileId: res.fileId,
      fileName: res.media.fileName,
      tag: 'file',
    };
    files.push(file);

    setValue('otherFiles', files);
  };

  // const staffImagesUploaded = (res: UploadResponse) => {
  //   const files = staffImages || [];
  //   const file = {
  //     fileId: res.fileId,
  //     fileName: res.media.fileName,
  //     tag: 'file',
  //   };
  //   files.push(file);

  //   setValue('staffImages', files);
  // };

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="text-center">Loading...</div>
      </>
    );
  }

  if (!formData) {
    // do something
  }

  const onSubmit = async (data) => {
    setSaving(true);
    setSavingDraft(true);

    console.log(data);

    await api.save(data);

    setSavingDraft(false);
    setSaving(false);

    const f = await api.get(formId);

    if (!f) {
      onNotFound();
    }
    setFormStatus(f.status);
    onLoadForm({
      ...(f.formData || ({} as any)),
      clientId: f.clientId,
      formId: f.formId,
      lastUpdated: f.lastUpdated as any,
    });
  };

  const onCompleteSubmit = async (data) => {
    setSaving(true);
    setIsCompleting(true);
    await api.save(data);

    // console.log(data);
    console.log('completing');

    const res = await api.complete(data);

    if (res.ok) {
      const f = (await res.json()) as any as Form<IFacebookAppForm>;

      setFormStatus(f.status);

      setSaving(false);
      setIsCompleting(false);
      return;
    }

    if (!res.ok && res.status == 400) {
      const errors = await res.json();
      console.log(errors);
      Object.keys(errors).forEach((e) =>
        setError(e as any, { message: errors[e] })
      );

      if (errors && Object.keys(errors).length > 0) {
        const newLocal = Object.keys(errors).filter(
          (e) => !ignoreErrorNames.includes(e)
        )[0];
        setFocus(newLocal as any);
      }
    }

    setSaving(false);
    setIsCompleting(false);
  };

  const onRemoveFile = (fieldName) => {
    setValue(fieldName as any, undefined);
  };

  const onRemoveGenericFile = (fileIndex: number) => {
    const files = otherFiles.filter((f, idx) => idx !== fileIndex);

    unregister(`otherFiles`);
    setValue(`otherFiles`, files);
  };

  // const onRemoveStaffImagesFile = (fileIndex: number) => {
  //   const files = staffImages.filter((f, idx) => idx !== fileIndex);

  //   unregister(`staffImages`);
  //   setValue(`staffImages`, files);
  // };

  if (formStatus === FormStatus.Complete) {
    return (
      <>
        <Header />
        <div className="container text-center mt-4">
          <h5>Thank you for completing your set-up form.</h5>
          <p>We will be in contact shortly to finalise your set-up</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />

      <div className="container mt-4">
        <div className="card-header">
          <h5 className="d-inline-block">Setup Form: Facebook App</h5>
          <div className="float-right">
            <label className={statusBadgeClass}>{statusText}</label>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                type="hidden"
                name="clientId"
                {...register('clientId')}
                defaultValue={clientId}
              />
              <input type="hidden" {...register('formId')} value={formId} />
              <input
                type="hidden"
                name="lastUpdated"
                value={watch('lastUpdated')}
              />
            </div>
            <div className="row">
              <div className="col-3">
                <label className="semi-bold">Entity Name</label>
              </div>
              <div className="col">
                <input
                  {...register('name')}
                  className="form-control mb-2"
                  placeholder="Entity Name"
                  type="text"
                />
                <ErrorSummary name="name" />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <label className="semi-bold">Trading Name</label>
              </div>
              <div className="col">
                <input
                  {...register('tradingName')}
                  className="form-control mb-2"
                  placeholder="Trading Name"
                  type="text"
                />
                <ErrorSummary name="tradingName" />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <label className="semi-bold">ABN</label>
              </div>
              <div className="col-3 mb-2">
                <input
                  {...register('abn')}
                  className="form-control"
                  placeholder="ABN"
                />
                <ErrorSummary name="abn" />
              </div>
              <div className="col-2">
                <label className="semi-bold">Phone Number</label>
              </div>
              <div className="col-4 ">
                <input
                  {...register('phoneNumber')}
                  className="form-control"
                  placeholder="Phone Number"
                />
                <ErrorSummary name="phoneNumber" />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <label className="semi-bold">Office Address</label>
              </div>
              <div className="col mb-2">
                <input
                  {...register('officeAddress')}
                  className="form-control"
                  placeholder="Office Address"
                />
                <ErrorSummary name="officeAddress" />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <label className="semi-bold">Office Website</label>
              </div>
              <div className="col">
                <input
                  {...register('officeWebsite')}
                  className="form-control"
                  placeholder="Office Website"
                />
                <ErrorSummary name="officeWebsite" />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 align-middle">
                <label className="semi-bold">Main Contact</label>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-2">
                    <label className="semi-bold">First Name</label>
                  </div>
                  <div className="col-4 mb-2">
                    <input
                      {...register('mainContact.firstName')}
                      className="form-control"
                      placeholder="First Name"
                    />
                    <ErrorSummary name="mainContact.firstName" />
                  </div>
                  <div className="col-2">
                    <label className="semi-bold">Last Name</label>
                  </div>
                  <div className="col-4 mb-2">
                    <input
                      {...register('mainContact.lastName')}
                      className="form-control"
                      placeholder="Last Name"
                    />
                    <ErrorSummary name="mainContact.lastName" />
                  </div>
                </div>
                <div className="row">
                  <div className="row col-6 p-0 m-0">
                    <div className="col-6">
                      <label className="semi-bold">Mobile Number</label>
                    </div>
                    <div className="col-6 mb-2">
                      <input
                        {...register('mainContact.mobileNumber')}
                        className="form-control"
                        placeholder="Mobile Number"
                      />
                    </div>
                    <div className="col-12">
                      <ErrorSummary name="mainContact.mobileNumber" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="semi-bold">Email</label>
                  </div>
                  <div className="col">
                    <input
                      {...register('mainContact.email')}
                      className="form-control"
                      placeholder="Email"
                    />
                    <ErrorSummary name="mainContact.email" />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 align-middle">
                <label className="semi-bold">
                  Accounts{' '}
                  <input
                    type="checkbox"
                    {...register('useMainContactForAccounts')}
                    defaultChecked={useMainContactForAccounts}
                  />{' '}
                  <small>Same as above</small>
                </label>
                <p className="application-form-description"></p>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-2">
                    <label className="semi-bold">First Name</label>
                  </div>
                  <div className="col-4 mb-2">
                    <input
                      {...register('accounts.firstName')}
                      disabled={useMainContactForAccounts}
                      className="form-control"
                      placeholder="First Name"
                    />
                    {!useMainContactForAccounts && (
                      <ErrorSummary name="accounts.firstName" />
                    )}
                  </div>
                  <div className="col-2">
                    <label className="semi-bold">Last Name</label>
                  </div>
                  <div className="col-4 mb-2">
                    <input
                      {...register('accounts.lastName')}
                      disabled={useMainContactForAccounts}
                      className="form-control"
                      placeholder="Last Name"
                    />
                    {!useMainContactForAccounts && (
                      <ErrorSummary name="accounts.lastName" />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="row col-6 p-0 m-0">
                    <div className="col-6">
                      <label className="semi-bold">Mobile Number</label>
                    </div>
                    <div className="col-6">
                      <input
                        {...register('accounts.mobileNumber')}
                        className="form-control"
                        disabled={useMainContactForAccounts}
                        placeholder="Mobile Number"
                      />
                    </div>
                    <div className="col-12">
                      {!useMainContactForAccounts && (
                        <ErrorSummary name="accounts.mobileNumber" />
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <label>
                      <small>
                        (must be mobile number as required by our merchant
                        provider, office number is not accepted)
                      </small>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="semi-bold">Email</label>
                  </div>
                  <div className="col">
                    <input
                      {...register('accounts.email')}
                      disabled={useMainContactForAccounts}
                      className="form-control"
                      placeholder="Email"
                    />
                    {!useMainContactForAccounts && (
                      <ErrorSummary name="accounts.email" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3 align-middle">
                    <label className="semi-bold">Listing Uploader</label>
                  </div>

                  <div className="col">
                    <ListingUploadSelect
                      selectedUploader={listingUploader}
                      onChange={onListingUploaderChange}
                    />
                    <ErrorSummary name="listingUploader" />
                  </div>
                </div>
                {listingUploader == ListingFeedProvider.Other && (
                  <div className="row">
                    <div className="offset-sm-3 col">
                      <label className="semi-bold">Other</label>
                      <input
                        {...register('listingUploaderOther')}
                        className="form-control"
                      />
                      <ErrorSummary name="listingUploaderOther" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3 align-middle">
                    <label className="semi-bold">Client Reviews: </label>
                  </div>

                  <div className="col">
                    <input
                      id="review-rma"
                      className="mr-2"
                      radioGroup="reviews"
                      type="radio"
                      value={ClientReviews.RMA}
                      {...register('clientReviews')}
                    />
                    <label htmlFor="review-rma" className="mr-2">
                      Import reviews from Rate My Agent
                    </label>
                    <br />
                    <input
                      id="review-google"
                      className="mr-2"
                      radioGroup="reviews"
                      type="radio"
                      value={ClientReviews.Google}
                      {...register('clientReviews')}
                    />
                    <label htmlFor="review-google" className="mr-2">
                      Import reviews from Google
                    </label>
                    <br />
                    <input
                      id="review-facebook"
                      className="mr-2"
                      radioGroup="reviews"
                      type="radio"
                      value={ClientReviews.Facebook}
                      {...register('clientReviews')}
                    />
                    <label htmlFor="review-facebook" className="mr-2">
                      Import reviews from Facebook
                    </label>
                    <br />
                    <input
                      id="review-client-provided"
                      className="mr-2"
                      radioGroup="reviews"
                      type="radio"
                      value={ClientReviews.ClientProvided}
                      {...register('clientReviews')}
                    />
                    <label htmlFor="review-client-provided" className="mr-2">
                      I will provide reviews
                    </label>
                  </div>
                  <ErrorSummary name="clientReviews" />
                </div>
                {clientReviews &&
                clientReviews == ClientReviews.ClientProvided ? (
                  <div className="row">
                    <div className="offset-sm-3 col">
                      {/* <label className="semi-bold">Client Reviews File</label> */}
                      <FileInputField
                        name={'clientProvidedReviews'}
                        formId={formId}
                        file={clientProvidedReviews}
                        onFileUploadComplete={fieldFileUploaded}
                        onRemoveFile={onRemoveFile}
                      />
                      <ErrorSummary name="clientProvidedReviews" />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 align-middle">
                <label className="semi-bold">Facebook</label>
              </div>

              <div className="col mb-2">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      facebook.com/
                    </span>
                  </div>
                  <input
                    {...register('facebook.url')}
                    className="form-control"
                    placeholder="Faceboook URL"
                  />
                  <div className="input-group-append">
                    <a
                      className="btn btn-outline-secondary"
                      href={`https://facebook.com/${facebookUrl}`}
                      target="_blank"
                    >
                      Test
                    </a>
                  </div>
                </div>
                <ErrorSummary name="facebook.url" />
              </div>
            </div>
            <div className="row">
              <div className="col-3 align-middle">
                <label className="semi-bold">Who is the Page Owner</label>
                <p className="application-form-description">
                  <small>
                    <i>(please use exact wording from Facebook)</i>
                  </small>
                </p>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-1">
                    <label className="semi-bold">Name: </label>
                  </div>
                  <div className="col mb-2">
                    <input
                      {...register('facebook.name')}
                      className="form-control"
                      placeholder="Page Owner"
                    />
                    <ErrorSummary name="facebook.name" />
                  </div>
                  <div className="col-1">
                    <label className="semi-bold">ID: </label>
                  </div>
                  <div className="col">
                    <input
                      {...register('facebook.id')}
                      className="form-control"
                      placeholder=""
                    />
                    <ErrorSummary name="facebook.id" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <small>
                        <i>
                          You can check this by going to your Page - Settings -
                          Page Roles -Page Owner. It could be a person or a
                          business
                        </i>
                      </small>
                    </p>
                    <p>
                      <small>
                        <b>
                          <i>
                            * If you are unsure who owns your Facebook Page
                            please call Michelle on 0405 213 203 to organise an
                            online meeting and screen share.
                          </i>
                        </b>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-3 align-middle">
                <label className="semi-bold">Page Owner Details</label>
                <p className="application-form-description">
                  <small>
                    <i>
                      (Who has full Admin Access to your Page Owner's account?)
                    </i>
                  </small>
                </p>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-2">
                    <label className="semi-bold">Name</label>
                  </div>
                  <div className="col-4 mb-2">
                    <input
                      {...register('facebookPageOwner.name')}
                      className="form-control"
                      placeholder="Name"
                    />
                    <ErrorSummary name="facebookPageOwner.name" />
                  </div>
                  <div className="row col-6 p-0 m-0">
                    <div className="col-6">
                      <label className="semi-bold">Mobile Number</label>
                    </div>
                    <div className="col-6">
                      <input
                        {...register('facebookPageOwner.mobileNumber')}
                        className="form-control"
                        placeholder="Mobile Number"
                      />
                    </div>
                    <div className="col-12">
                      <ErrorSummary name="facebookPageOwner.mobileNumber" />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-2">
                    <label className="semi-bold">Email</label>
                  </div>
                  <div className="col">
                    <input
                      {...register('facebookPageOwner.email')}
                      className="form-control"
                      placeholder="Email"
                    />
                    <ErrorSummary name="facebookPageOwner.email" />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mb-3">
              <div className="col-3">
                <label className="semi-bold">
                  Who should we send customer enquiries from the App to?
                </label>
              </div>
              <div className="col-1">
                <label className="semi-bold">Email</label>
              </div>
              <div className="col">
                <input
                  {...register('enquiresEmail')}
                  className="form-control"
                  placeholder="Email"
                />
                <ErrorSummary name="enquiresEmail" />
              </div>
            </div>
            <hr />

            <label className="semi-bold">
              <h5> Files</h5>
            </label>
            <div className="row mb-3">
              <div className="col-lg-3 col-sm-6 mb-sm-3">
                <label className="semi-bold">Company Logo</label>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2">
                <FileInputField
                  name={'logo'}
                  formId={formId}
                  file={logo}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="logo" />
              </div>
              <div className="col-lg-3 col-sm-6">
                <label className="semi-bold">Cover Image</label>
              </div>
              <div className="col-lg-3 col-sm-6  mb-2">
                <FileInputField
                  name={'heroImage'}
                  formId={formId}
                  file={heroImage}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="heroImage" />
              </div>
            </div>
            {/* Staff Images 1 */}
            <div className="row mb-3">
              <div className="col-lg-3 col-sm-6 mb-sm-3">
                <label className="semi-bold">Staff Contact Details</label>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2">
                <FileInputField
                  name={'staffContactDetails'}
                  formId={formId}
                  file={staffContactDetails}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffContactDetails" />
              </div>
              <div className="col-lg-3 col-sm-6">
                <label className="semi-bold">Staff 1 Profile Pic</label>
              </div>
              <div className="col-lg-3 col-sm-6  mb-2">
                <FileInputField
                  name={'staffPic1'}
                  formId={formId}
                  file={staffPic1}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffPic1" />
              </div>
            </div>
            {/* Staff Images 2 */}
            <div className="row mb-3">
              <div className="col-lg-3 col-sm-6 mb-sm-3">
                <label className="semi-bold">Staff 2 Profile Pic</label>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2">
                <FileInputField
                  name={'staffPic2'}
                  formId={formId}
                  file={staffPic2}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffPic2" />
              </div>
              <div className="col-lg-3 col-sm-6">
                <label className="semi-bold">Staff 3 Profile Pic</label>
              </div>
              <div className="col-lg-3 col-sm-6  mb-2">
                <FileInputField
                  name={'staffPic3'}
                  formId={formId}
                  file={staffPic3}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffPic3" />
              </div>
            </div>
            {/* Staff Images 3 */}
            <div className="row mb-3">
              <div className="col-lg-3 col-sm-6 mb-sm-3">
                <label className="semi-bold">Staff 4 Profile Pic</label>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2">
                <FileInputField
                  name={'staffPic4'}
                  formId={formId}
                  file={staffPic4}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffPic4" />
              </div>
              <div className="col-lg-3 col-sm-6">
                <label className="semi-bold">Staff 5 Profile Pic</label>
              </div>
              <div className="col-lg-3 col-sm-6  mb-2">
                <FileInputField
                  name={'staffPic5'}
                  formId={formId}
                  file={staffPic5}
                  onFileUploadComplete={fieldFileUploaded}
                  onRemoveFile={onRemoveFile}
                />
                <ErrorSummary name="staffPic5" />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <label className="semi-bold">
                  <h5>Other Files</h5>
                </label>
                <table className="table table-striped">
                  <thead>
                    <th>File Tag</th>
                    <th>File Name</th>
                    <th>Actions</th>
                  </thead>
                  <tbody>
                    {otherFiles &&
                      otherFiles?.map((file, index) => {
                        return (
                          <tr key={file.fileId}>
                            <td>
                              <input
                                type="hidden"
                                name={`otherFiles.${index}.fileId`}
                                {...register(`otherFiles.${index}.fileId`)}
                                defaultValue={file.fileId}
                              />
                              <input
                                type="hidden"
                                name={`otherFiles.${index}.fileName`}
                                {...register(`otherFiles.${index}.fileName`)}
                                defaultValue={file.fileName}
                              />
                              {file.fileName}
                            </td>
                            <td>
                              <input
                                type="hidden"
                                name={`otherFiles.${index}.tag`}
                                {...register(`otherFiles.${index}.tag`)}
                                defaultValue={file.tag}
                              />
                              {file.tag}{' '}
                              <a
                                className="text-danger"
                                onClick={() => onRemoveGenericFile(index)}
                              >
                                aaa <i className="fa fa-trash"></i>
                              </a>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={3}>
                        <FileUploadInput
                          formId={formId}
                          onFileUploadComplete={otherFilesUploaded}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mb-4 pb-5">
              <div className="col">
                <strong>
                  * If you have any queries about this form please call Michelle
                  on 0405 213 203
                </strong>
              </div>
            </div>

            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                background: '#fff',
                padding: '15px 0',
                margin: '0 15px',
                borderTop: '1px solid #ddd',
                zIndex: 1000,
              }}
            >
              <div className="container">
                <div className="margin-top-10">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isSaving}
                    >
                      {!isSavingDraft ? 'Save Changes' : <Spinner loading />}
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit(onCompleteSubmit)}
                      disabled={isSaving}
                    >
                      {!isCompleting ? 'Complete' : <Spinner loading />}
                    </button>
                    {errors && Object.keys(errors).length > 0 && (
                      <span className="text-danger ml-4">
                        {Object.keys(errors).length} Errors
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
