import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';

import { StoreProvider } from './util/useStore';
import { RootStores } from './stores';
import App from './app';

// @ts-ignore
import $ from 'jquery';
// @ts-ignore
import Popper from 'react-popper';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/scss/font-awesome.scss';
import './assets/global.css';

// prepare MobX stores
const history = createBrowserHistory();
export const stores = new RootStores(history).getStoresInstances();

// prepare App
// const App = NewApp(history);

ReactDOM.render(
  <Provider {...stores}>
    <StoreProvider value={stores}>
      <App history={history} />
    </StoreProvider>
  </Provider>,
  document.getElementById('root')
);
