export enum APP_ENVIRONMENT {
    Undefined,
    Local,
    Staging,
    Development,
    Production
}

export function appEnvrionmentFromString(value: string): APP_ENVIRONMENT {
    switch (value) {
        case "local":
            return APP_ENVIRONMENT.Local;
        case "development":
            return APP_ENVIRONMENT.Staging;
        case "staging":
            return APP_ENVIRONMENT.Staging;
        case "production":
            return APP_ENVIRONMENT.Production;
        default:
            return APP_ENVIRONMENT.Undefined;
    }
}
