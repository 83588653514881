import * as React from 'react';

import { Route, Router, Switch } from 'react-router-dom';
// import { TodoApp } from 'app/containers/TodoApp';
import { History } from 'history';
import { ClientForm } from './pages';
import { FacebookAppForm } from './pages/Forms/FacebookAppForm';

interface AppContainerProps {
  history: History;
}

interface AppContainerState {}

class AppContainer extends React.Component<
  AppContainerProps,
  AppContainerState
> {
  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route path="/forms/:formId" component={FacebookAppForm} />
          <Route path="/" component={ClientForm} />
        </Switch>
      </Router>
    );
  }
}

export default AppContainer