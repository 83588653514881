const API_URL = process.env.REACT_APP_API_URL;

export class FormsApiClient {
  get = async (formId: string): Promise<Form<IFacebookAppForm>> => {
    const res = await fetch(`${API_URL}/api/public/forms/${formId}`, {
      method: 'GET',
    });

    if (!res.ok) return null;

    return res.json() as any as Form<IFacebookAppForm>;
  };

  save = async (form: IFacebookAppForm) => {
    const res = await fetch(
      `${API_URL}/api/public/forms/${form.formId}/save-facebook`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
        method: 'post',
      }
    );

    return res;
  };

  complete = async (form: IFacebookAppForm): Promise<Response> => {
    const res = await fetch(
      `${API_URL}/api/public/forms/${form.formId}/complete`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
        method: 'post',
      }
    );

    return res;
  };
}

export class Form<T extends IForm> {
  formId: string;
  clientId: string;
  type: FormType;
  status: FormStatus;
  formData: T;
  lastUpdated: Date;
}

export enum FormType {
  FacebookApp,
}

export enum FormStatus {
  Sent = 'Sent',
  InProgress = 'InProgress',
  Complete = 'Complete',
}

interface IForm {
  type: FormType;
}

export enum ClientReviews {
  RMA = 'RMA',
  Google = 'Google',
  Facebook = 'Facebook',
  ClientProvided = 'ClientProvided',
}

export interface IFacebookAppForm extends IForm {
  formId: string;
  clientId: string;
  lastUpdated: string;
  name: string;
  tradingName: string;
  abn: string;
  phoneNumber: string;
  officeAddress: string;
  officeWebsite: string;
  mainContact: Contact;
  accounts: Contact;
  useMainContactForAccounts: boolean;
  enquiresEmail: string;
  clientReviews: ClientReviews;
  clientProvidedReviews: File;
  listingUploader: ListingFeedProvider;
  listingUploaderOther: string;
  facebook: {
    url: string;
    name: string;
    id: string;
  };
  // businessManager: {
  //   name: string;
  //   mobileNumber: string;
  //   email: string;
  //   businessId: string;
  // };
  facebookPageOwner: { name: string; mobileNumber: string; email: string };
  type: FormType;
  otherFiles: File[];

  logo: File;
  heroImage: File;
  // staffImages: File[];

  staffContactDetails: File;
  staffPic1: File;
  staffPic2: File;
  staffPic3: File;
  staffPic4: File;
  staffPic5: File;
}

export interface File {
  tag: string;
  fileName: string;
  fileId: string;
}

interface Contact {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
}

enum FileTags {
  ClientLogo,
  HeroImage,
  AgentImages,
  AgentContactDetails,
  BrandingGuide,
  Other,
}

export enum ListingFeedProvider {
  VaultRE = 'Vault RE',
  MyDesktop = 'MyDesktop',
  // Console,
  ListOnce = 'List Once',
  AgentBox = 'AgentBox',
  BoxAndDice = 'Box + Dice',
  Rex = 'Rex',
  LockedOn = 'Locked On',
  Zenu = 'Zenu',
  EagleSoftware = 'Eagle Software',
  AgentPoint = 'AgentPoint',
  HarcourtsH1 = 'Harcourts H1',
  LJHookerMove = 'LJ Hooker Move',

  Other = 'Other',
}
