import { History } from 'history';
import { RouterStore } from './RouterStore';
import { STORE_ROUTER, STORE_FORMS } from '../constants';
import { FormsStore } from './FormsStore';

export class RootStores {
  routerStore: RouterStore;
  formsStore: FormsStore;

  constructor(history: History) {
    this.routerStore = new RouterStore(history);
    this.formsStore = new FormsStore();
  }

  getStoresInstances(): Stores & { [store: string]: any } {
    return {
      [STORE_ROUTER]: this.routerStore,
      [STORE_FORMS]: this.formsStore,
    };
  }
}

export type Stores = {
  [STORE_FORMS]: FormsStore
  [STORE_ROUTER]: RouterStore
} & { [store: string]: any };

export default RootStores;
