import { ListingFeedProvider } from 'api-clients/FormsApiClient';

export function enumKeys(myEnum: any): { [k: string]: string } {
  return Object.keys(myEnum)
    .filter((o) => typeof o == 'string')
    .reduce((prev, curr) => {
      prev[curr] = myEnum[curr];
      return prev;
    }, {});
}

interface ListingUploaderSelectProps {
  selectedUploader?: string;
  onChange: (value: string) => void;
}

export const ListingUploadSelect = ({
  selectedUploader,
  onChange,
}: ListingUploaderSelectProps) => {
  const listingUploaders = enumKeys(ListingFeedProvider);
  return (
    <select
      className="form-control"
      onChange={(e) => onChange(e.currentTarget.value)}
      defaultValue={selectedUploader}
    >
      <option value="">- Select Uploader -</option>
      {Object.keys(listingUploaders)
        .sort()
        .map((k) => (
          <option key={k} value={k}>
            {listingUploaders[k]}
          </option>
        ))}
    </select>
  );
};
