import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
// import { useHistory } from 'react-router-dom'

import {
  FormsApiClient,
  FormStatus,
  FormType,
} from '../api-clients/FormsApiClient';
import LoadingSpinner from '../components/LoadingSpinner';
// import { useStore } from 'util/useStore';
import logoSrc from 'assets/RS_logo_Colour.png'

interface FormDetails {
  id: string;
  type: FormType;
  status: FormStatus;
}

const api = new FormsApiClient();

export const ClientForm = observer(() => {
  // const {forms } = useStore();
  const [formId, setFormId] = useState<string>(null);
  const [isLoading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [formDetails, setFormDetails] = useState<FormDetails>(null);

  // const history = useHistory();

  const formIdRef = useRef<HTMLInputElement>();


  useEffect(() => {
    formIdRef.current.focus();
  }, [formId,formIdRef]);

  const findForm = async () => {
    setLoading(true);
    setNotFound(false);
    setFormDetails(null);

    const f = await api.get(formId);

    if (!f) {
      setNotFound(true);
      setLoading(false);
      return;
    }

    setFormDetails({
      id: f.formId,
      status: f.status,
      type: f.type,
    });
    setLoading(false);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  };

  const navigateToForm = () => {};

  return (
    <div className="h-100">
      <div className="h-50 p-4" style={{ background: '#ddd' }}>
        <div className="container" style={{ paddingTop: '50px' }}>
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-8 col-lg-6 text-center">
              <img src={logoSrc} />

              <h3 className="text-center" style={{ padding: '25px 0 25px' }}>
                Real Software Client Forms
              </h3>
              <div className="input-group">
                <input
                  ref={formIdRef}
                  onChange={(c)=> setFormId(c.currentTarget.value)}
                  className="form-control"
                  type="text"
                  placeholder="Enter your Form Id e.g. A2KY3TP"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={findForm}
                    style={{ width: '76px' }}
                    disabled={isLoading}
                  >
                    {!isLoading ? 'Find' : <LoadingSpinner color="#343a40" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {!notFound && formDetails && (
          <div className="container" style={{ paddingTop: '50px' }}>
            <div className="row justify-content-md-center text-center">
              <div className="col-6 col-md-3">
                <div>
                  <h3 style={{ display: 'inline-block' }}>Form: </h3>{' '}
                  <span>Real Facebook App</span>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <h3 style={{ display: 'inline-block' }}>Status: </h3>{' '}
                <span>Not Started</span>
              </div>
            </div>
            <div className="row justify-content-md-center text-center">
              <div className="col-md-3 mt-4"></div>
            </div>
            <div className="row justify-content-md-center text-center">
              <div className="col-md-4 mt-4">
                <div className="input-group">
                  <input
                    
                    type="text"
                    name="pin"
                    className="form-control"
                    placeholder="Enter form pin e.g. 1234"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      onClick={navigateToForm}
                    >
                      Open Form
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
});

const Footer = () => {
  return <div className="container"></div>;
};
