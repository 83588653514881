import {
  // action,
  makeAutoObservable,
  // observable
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  IFacebookAppForm,
  Form,
  FormsApiClient,
} from '../api-clients/FormsApiClient';

export class FormsStore {
  //   @observable
  isLoading: boolean = true;
  //   @observable
  form: Form<IFacebookAppForm> = null;

  //   @observable
  previouslySearchedForms: string[] = [];

  private api: FormsApiClient;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(
      this,
      {
        name: 'FormsStore',
        properties: ['form'],
        expireIn: 360 * 60 * 1000, // 15 minutes so 900.000 milliseconds
        removeOnExpiration: true,
        storage: window.localStorage,
      },
      { delay: 200, fireImmediately: true }
    );

    this.api = new FormsApiClient();
  }

  //   @action
  getForm = async (formId: string) => {
    this.setLoading(true);

    this.form = await this.api.get(formId);

    this.setLoading(false);
  };

  //   @action
  setLoading = (val: boolean) => {
    this.isLoading = val;
  };
}
