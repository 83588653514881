import {
  LoaderHeightWidthProps,
  LoaderSizeProps,
  LoaderSizeMarginProps,
  LoaderHeightWidthRadiusProps,
  CommonProps,
} from '../interfaces';

/*
 * DefaultProps object for different loaders
 */
const commonValues: Required<CommonProps> = {
  loading: true,
  color: '#000000',
  css: '',
  speedMultiplier: 1,
};

export const sizeDefaults = (sizeValue: number): Required<LoaderSizeProps> => {
  return Object.assign({}, commonValues, { size: sizeValue });
};

export const sizeMarginDefaults = (
  sizeValue: number
): Required<LoaderSizeMarginProps> => {
  return Object.assign({}, sizeDefaults(sizeValue), {
    margin: 2,
  });
};

export const heightWidthDefaults = (
  height: number,
  width: number
): Required<LoaderHeightWidthProps> => {
  return Object.assign({}, commonValues, {
    height,
    width,
  });
};

export const heightWidthRadiusDefaults = (
  height: number,
  width: number,
  radius = 2
): Required<LoaderHeightWidthRadiusProps> => {
  return Object.assign({}, heightWidthDefaults(height, width), {
    radius,
    margin: 2,
  });
};
