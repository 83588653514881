import React, { ChangeEvent, useRef, useState } from 'react';
import {
  MediaApiClient,
  UploadResponse,
} from '../../api-clients/MediaApiClient';

import LoadingSpinner, { Spinner } from 'components/LoadingSpinner';

const api = new MediaApiClient();

interface FileUploadComponentProps {
  formId: string;
  forField?: string;
  allowExtensions?: string[];
  onFileUploadComplete: (res: UploadResponse, forField?: string) => void;
}

const FileUploadComponent = ({
  formId,
  forField,
  allowExtensions,
  onFileUploadComplete,
}: FileUploadComponentProps) => {
  const fileInputRef = useRef<HTMLInputElement>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  //   const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
  //     const file = e.currentTarget.files.item(0);
  //     setSelectedFile(file);
  //     const newLocal = file != null;
  //     setIsSelected(newLocal);
  //     console.log(newLocal);

  //     await handleSubmission()
  //   };
  const resetUpload = () => {
    setIsSelected(false);
    setIsUploading(false);
    fileInputRef.current.value = null;
  };

  const handleSubmission = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files.item(0);

    if (!file) {
      setIsSelected(false);
      return;
    }

    setIsSelected(true);
    setIsUploading(true);

    const res = await api.upload({
      file: file,
      formId: formId,
    });

    if (!res) {
     
        return;
    }

    resetUpload();
    onFileUploadComplete(res, forField);
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        onChange={handleSubmission}
      />
      <div>
        {isUploading && `Uploading...`}
        {<Spinner loading={isUploading} />}
      </div>

      {/* <div> */}
      {/* <button onClick={handleSubmission}>Submit</button> */}
      {/* </div> */}
    </div>
  );
};

export default FileUploadComponent;
